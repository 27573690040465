import clsx from 'clsx';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export function Button({ children, className, ...rest }: ButtonProps) {
  const { disabled } = rest;

  return (
    <button
      {...rest}
      className={clsx(
        'bg-apricot-button active:bg-apricot-button_hover flex h-10 items-center rounded-lg px-4 text-sm font-medium text-white transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
        {
          'hover:bg-apricot-button_hover': !disabled,
          'pointer-events-none cursor-default': disabled,
        },
        className,
      )}
    >
      {children}
    </button>
  );
}
