'use client';

import { authenticate } from '@/app/lib/actions';
import {
  ArrowPathIcon,
  ArrowRightIcon,
  AtSymbolIcon,
  ExclamationCircleIcon,
  KeyIcon,
} from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useFormState, useFormStatus } from 'react-dom';
import { Button } from './button';

export default function LoginForm() {
  const [errorMessage, dispatch] = useFormState(authenticate, undefined);

  return (
    <form action={dispatch}>
      <div className="flex-1 rounded-lg bg-apricot-fore px-6 pb-4 pt-8 drop-shadow-sm">
        <h1 className="text-2xl">登入</h1>

        <div className="w-full">
          {/* email */}
          <div>
            <label
              className="mb-1 mt-5 block text-sm font-medium"
              htmlFor="email"
            >
              帳號
            </label>
            <div className="relative">
              <input
                className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
                id="email"
                type="email"
                name="email"
                placeholder="臺大信箱 (@ntu.edu.tw)"
                required
                autoComplete="email"
              />
              <AtSymbolIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
            </div>
          </div>

          {/* password */}
          <div className="mt-4">
            <div className="mb-1 mt-5 flex justify-between text-sm">
              <label className="block font-medium" htmlFor="password">
                密碼
              </label>
              {/* // FIXME: hide forgot-password for now to count down on email consumption */}
              {/* <Link
                href="/forgot-password"
                className="text-gray-500 underline underline-offset-2 hover:text-apricot-text"
                tabIndex={-1}
              >
                忘記密碼
              </Link> */}
            </div>
            <div className="relative">
              <input
                className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
                id="password"
                type="password"
                name="password"
                placeholder="密碼"
                required
                minLength={6}
                autoComplete="current-password"
              />
              <KeyIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
            </div>
          </div>
        </div>

        {/* error messages */}
        <div
          className="relative flex h-6 items-end space-x-1"
          aria-live="polite"
          aria-atomic="true"
        >
          {errorMessage && (
            <>
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
              <p className="text-sm text-red-500">{errorMessage}</p>
              {/* <p className="text-sm text-red-500">帳號或密碼錯誤</p> */}
            </>
          )}
        </div>

        <LoginButton />

        {/* Link to registration page */}
        <div className="flex w-full justify-center pt-5 text-sm text-gray-500">
          還沒有帳號？{' '}
          <Link
            href="/signup"
            className="underline underline-offset-2 hover:text-apricot-text"
          >
            註冊
          </Link>
        </div>
      </div>
    </form>
  );
}

function LoginButton() {
  const { pending } = useFormStatus();

  return (
    <Button
      className="flex-rol mt-4 flex w-full justify-center p-4"
      aria-disabled={pending}
    >
      登入
      {pending ? (
        <ArrowPathIcon className="h-5 w-5 animate-spin text-gray-50" />
      ) : (
        <ArrowRightIcon className="h-5 w-5 text-gray-50" />
      )}
    </Button>
  );
}
